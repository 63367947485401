import React from "react"

import { Link } from "gatsby"

import { Container, Specs, Spec } from "./ListingHero.styles"

//icons
import ArrowBack from "../../assets/icons/arrow-back.svg"
import LocationWhite from "../../assets/icons/location-drop-white.svg"
import Type from "../../assets/icons/type-icon.svg"
import Accomodation from "../../assets/icons/accomodation-icon.svg"
import Bedroom from "../../assets/icons/bedroom-icon.svg"
import Bathroom from "../../assets/icons/bathroom-icon.svg"

function commafy(num) {
  const numberWithComma = Number(num).toLocaleString()
  return numberWithComma
}
const ListingHero = ({ listing, arabic }) => {
  console.log("currency:", listing.selectedCurrency)
  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-container">
            <Link to="/find-a-property">
              <img src={ArrowBack} alt="arrow back" />
              <span>{arabic === false ? "BACK TO LIST" : "الرجوع للقائمة"}</span>
            </Link>
            <h1>{listing.title}</h1>
            <div className="location">
              <img src={LocationWhite} alt="location white" />
              <span>
                {listing?.address || listing.street_name},
                {listing.country.label}
              </span>
            </div>
            <div className="price">
              {listing.sale_price > 0 && (
                <h3>
                  {arabic === false ? "Sale Price:" : "سعر البيع:"}{" "}
                  {commafy(listing.sale_price)} USD
                </h3>
              )}
              {listing.rent_price && (
                <h3>
                  {arabic === false ? "Rent Price:" : "سعر الإيجار:"}{" "}
                  {commafy(listing.rent_price)} USD/
                  {arabic === false ? "month" : "شهرياً"}
                </h3>
              )}
              {listing.placedby && (
                <h3>
                  {arabic === false ? "Placed By:" : "وضعت من قبل:"}{" "}
                  {listing.placedby}
                </h3>
              )}
            </div>

            <Specs>
              <Spec>
                <img src={Type} alt="type icon" />
                <div className="spec-title">
                  {arabic === false ? "Type" : "نوع الملكية"}
                </div>
                <div className="spec-description">{listing.type.label}</div>
              </Spec>
              <Spec>
                <img src={Accomodation} alt="accomodation icon" />
                <div className="spec-title">
                  {arabic === false ? "Floors" : "عدد الطوابق"}
                </div>

                <div className="spec-description">
                  {listing.num_floors !== "0"
                    ? `${listing.num_floors} Floors`
                    : "غير محدد"}
                </div>
              </Spec>
              <Spec>
                <img src={Bedroom} alt="bedroom icon" />
                <div className="spec-title">
                  {arabic === false ? "Bedrooms" : "غرف نوم"}
                </div>
                <div className="spec-description">
                  {listing.num_bedrooms
                    ? `${listing.num_bedrooms} Bedrooms`
                    : "غير محدد"}
                </div>
              </Spec>
              <Spec>
                <img src={Bathroom} alt="bedroom icon" />
                <div className="spec-title">
                  {arabic === false ? "Bathrooms" : "حمامات"}
                </div>
                <div className="spec-description">
                  {listing.num_bathrooms
                    ? `${listing.num_bathrooms} Bathrooms`
                    : "غير محدد"}
                </div>
              </Spec>
            </Specs>
          </div>
          <div className="col-lg-6 image-container">
            <img
              src={listing.featured_image.url}
              alt="listing"
              className="hero-img"
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ListingHero
