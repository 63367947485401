import React, { Fragment, useEffect, useMemo, useState } from "react"
import {
  GoogleMap,
  LoadScript,
  Marker,
  useLoadScript,
} from "@react-google-maps/api"
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox"
import { InputContainer } from "./GlobalComponents/GlobalComponents.styles"

export default function MapDisplayOnly({ lat, lng, zoom = 10 }) {
  const center = useMemo(() => ({ lat, lng, zoom }), [])
  const [selected, setSelected] = useState({ lat, lng })

  useEffect(() => {
    console.log("lat and lng changed", lat + " and " + lng)
    setSelected({ lat: parseFloat(lat), lng: parseFloat(lng) })
  }, [lat, lng])

  // for the new state management of the new map
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC4y18vbbKkq4L7h0Wu6-Vzs6VTj11MnmU",
    libraries: ["places"],
  })

  return (
    <Fragment>
      {isLoaded && (
        <GoogleMap
          zoom={zoom}
          center={!selected ? center : selected}
          mapContainerClassName="map-container"
        >
          {selected && <Marker position={selected} />}
        </GoogleMap>
      )}
    </Fragment>
  )
}
