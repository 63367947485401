import React, { useEffect, useState, useContext, useRef } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import ListingHero from "../../components/ListingHero"
import ListingDetails from "../../components/LisitngDetails"
import Seo from "../../components/seo"
import { doc, getDoc } from "firebase/firestore"
import { db } from "../../firebase/config"
import { graphql } from "gatsby"
import { StateContext } from "../../helpers/StateProvider"
import Loader from "../../components/Loader"
const Property = ({ params }) => {
  const [data, setData] = useState("")
  const listingId = params.id
  useEffect(() => {
    const docRef = doc(db, "property", listingId)
    getDoc(docRef).then(docSnap => {
      if (docSnap) {
        setData(docSnap.data())
      } else {
        setData(null)
      }
      console.log("db data", docSnap.data())
    })
  }, [])
  const { language, setLanguage } = useContext(StateContext)
  const { authIsReady } = useAuthContext()
  const url = typeof window !== "undefined" ? window.location.href : ""
  console.log("data", url)

  return (
    <>
      {authIsReady && (
        <>
          {data && (
            <>
              <Seo
                image={data && data.featured_image.url}
                title={`Property Detail - ${data.title}`}
                metaDescription="Property"
                url={url}
              />
              <ListingHero listing={data} arabic={language} />
              <ListingDetails listing={data} arabic={language} listingId={params.id}/>
            </>
          )}
          {!data && <Loader />}
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
  }
`
export default Property
