import React, { useState, useRef } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import emailjs from "emailjs-com"

import {
  Container,
  MediaContent,
  Overview,
  PropertyDetails,
  Separator,
  Share,
  Spec,
  UnitSpec,
  FormContent,
} from "./ListingDetails.styles"
import Viewvideo from "../AddListingProcess/video/Viewvideo"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TumblrShareButton,
} from "react-share"

import UnitCheck from "../../assets/icons/unit-check.svg"

//share icons
import Faceboook from "../../assets/icons/facebook-share.svg"
import Twitter from "../../assets/icons/twitter-share.svg"
import Linkedin from "../../assets/icons/linkedin-share.svg"
import Whatsapp from "../../assets/icons/whatsapp-icon.svg"
import Tumblr from "../../assets/icons/tumblr-logo.svg"
// import Tiktok from "../../assets/icons/tiktok.svg"
// import { useAuthContext } from "../../hooks/useAuthContext"
// import Modal from 'react-bootstrap/Modal';
import MapDisplayOnly from "./../MapDisplayOnly"
const ListingDetails = ({ listing, arabic,listingId }) => {
  const form = useRef()

  const validation = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      number: "",
      dateFrom: "",
      dateTo: "",
      timeFrom: "",
      timeTo: "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required("This Field is required"),
      lastName: Yup.string().required("This Field is required"),
      emailAddress: Yup.string().required("This Field is required"),
      number: Yup.string().required("This Field is required"),
      dateFrom: Yup.string().required("This Field is required"),
      dateTo: Yup.string().required("This Field is required"),
      timeFrom: Yup.string().required("This Field is required"),
      timeTo: Yup.string().required("This Field is required"),
    }),

    onSubmit: values => {
      emailjs.send(
        "opd_mailing_service",
        "property_interest",
        {
          img: listing.featured_image.url,
          date_from: values.dateFrom,
          hour_from: values.timeFrom,
          date_to: values.dateTo,
          hour_to: values.timeTo,
          name: values.firstName + " " + values.lastName,
          number: values.number,
          email: values.emailAddress,
        },
        "g1dJssA6i85wnSF7J"
      )
      setMessageSent("True")
    },
  })
  const [messageSent, setMessageSent] = useState(null)
  const currentUrl = `https://us-central1-opddev-51cfb.cloudfunctions.net/socialShare?id=${listingId}`
console.log("listing",listing);
  return (
    <Container>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-8"
            style={{ direction: arabic ? "rtl" : "ltr" }}
          >
            <Overview>
              {/* <p>Listed by: {user.displayName}</p> */}
              <h4>{arabic === false ? "Overview" : "نظرة عامة"}</h4>
              <p>{listing.description}</p>
            </Overview>
            <MediaContent>
              {listing.gallery &&
                listing.gallery.map(image => {
                  return (
                    <div className="img" key={image.url}>
                      <img
                        src={image.url}
                        alt="gallery"
                        className="listing-image"
                      />
                    </div>
                  )
                })}
            </MediaContent>
            {listing.upvideourl && (
              <>
                <hr />
                <Viewvideo video={listing.upvideourl} />
              </>
            )}
            <hr />
            <MapDisplayOnly
              lat={listing.latitude}
              lng={listing.longitude}
              zoom={18}
            />

            <PropertyDetails>
              <h4>
                {arabic === false
                  ? "Property Information"
                  : "معلومات عن العقار"}
              </h4>
              <div className="row">
                {Object.keys(listing.type).length !== 0 && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "Property Type" : "نوع الملكية"}
                      </p>
                      <p className="value">{listing.type.label}</p>
                    </Spec>
                  </div>
                )}
                {Object.keys(listing.type_subcategory).length !== 0 && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "Property Category" : "فئة الملكية"}
                      </p>
                      <p className="value">{listing.type_subcategory.label}</p>
                    </Spec>
                  </div>
                )}
              </div>
            </PropertyDetails>
            <Separator />
            <PropertyDetails>
              <h4>{arabic === false ? "Property Location" : "موقع العقار"}</h4>
              <div className="row">
                {Object.keys(listing.country).length !== 0 && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "Country" : "دولة"}
                      </p>
                      <p className="value">{listing.country.label}</p>
                    </Spec>
                  </div>
                )}
                {listing.district && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "District" : "القضاء"}
                      </p>
                      <p className="value">{listing.district.label}</p>
                    </Spec>
                  </div>
                )}
                {listing.governate && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "Governate" : "المحافظة "}
                      </p>
                      <p className="value">{listing.governate.label}</p>
                    </Spec>
                  </div>
                )}
                {listing.town && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "Town" : "البلدة"}
                      </p>
                      <p className="value">{listing.town}</p>
                    </Spec>
                  </div>
                )}
                {listing.city && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "City" : "المدينة"}
                      </p>
                      <p className="value">{listing.city}</p>
                    </Spec>
                  </div>
                )}
                {listing.village && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "Village" : "القرية"}
                      </p>
                      <p className="value">{listing.village}</p>
                    </Spec>
                  </div>
                )}
                {listing.street_name && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "Street Name" : "اسم الشارع"}
                      </p>
                      <p className="value">{listing.street_name}</p>
                    </Spec>
                  </div>
                )}
                {listing.building && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "Building" : "مبنى"}
                      </p>
                      <p className="value">{listing.building}</p>
                    </Spec>
                  </div>
                )}
                {listing.adress && (
                  <div className="col-lg-4">
                    <Spec>
                      <p className="label">
                        {arabic === false ? "Address" : "تبوك"}
                      </p>
                      <p className="value">{listing.address}</p>
                    </Spec>
                  </div>
                )}
              </div>
            </PropertyDetails>
            <Separator />
            {listing.type_subcategory.value === "hotel" && (
              <PropertyDetails>
                <h4>{arabic === false ? "Hotel Details" : "تفاصيل الفندق"}</h4>
                <div className="row">
                  {listing.numOfFloors !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number of Floors"
                            : "عدد الطوابق"}
                        </p>
                        <p className="value">{listing.numOfFloors}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfReceptionStations !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number of Reception Stations"
                            : "عدد محطات الاستقبال"}
                        </p>
                        <p className="value">
                          {listing.numOfReceptionStations}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfAdminstrationOffices !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number of Administration Offices"
                            : "عدد مكاتب الإدارة"}
                        </p>
                        <p className="value">
                          {listing.numOfAdminstrationOffices}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfKinglslySuits !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number of Kinglsly Suits"
                            : "عدد الدعاوى Kinglsly"}
                        </p>
                        <p className="value">{listing.numOfKinglslySuits}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.areaOfKingslySuitInSMetersOrSFeet !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Area Of King SlySuit In Square Meters Or Square Feet"
                            : "مساحة كينج سلاي بالمتر المربع أو الأقدام المربعة"}
                        </p>
                        <p className="value">
                          {listing.areaOfKingslySuitInSMetersOrSFeet}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfRegularSuits !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Regular Suits"
                            : "عدد البدلات العادية"}
                        </p>
                        <p className="value">{listing.numOfRegularSuits}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.areaOfRegularSuitsInSMetersOrSFeet !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Area Of Regular Suits In Square Meters Or Square Feet"
                            : "مساحة البدل العادية بالمتر المربع أو الأقدام المربعة"}
                        </p>
                        <p className="value">
                          {listing.areaOfRegularSuitsInSMetersOrSFeet}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfSingleBedroomsWithBath !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Single Bedrooms With Bath"
                            : "عدد غرف النوم المفردة بحمام"}
                        </p>
                        <p className="value">
                          {listing.numOfSingleBedroomsWithBath}
                        </p>
                      </Spec>
                    </div>
                  )}
                  {listing.areaOfSingleBedroomsWithBathInSMetersOrSFeet !==
                    "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Area Of Single Bedrooms With Bath In Square Meters Or Square Feet"
                            : "مساحة غرف النوم المفردة بحمام في الأمتار المربعة أو الأقدام المربعة"}
                        </p>
                        <p className="value">
                          {listing.areaOfSingleBedroomsWithBathInSMetersOrSFeet}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfDoubleBedroomsWithBath !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Double Bedrooms With Bath"
                            : "عدد غرف النوم المزدوجة مع حمام"}
                        </p>
                        <p className="value">
                          {listing.numOfDoubleBedroomsWithBath}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.areaOfDoubleBedroomsWithBathInSMetersOrSFeet !==
                    "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Area Of Double Bedrooms With Bath In Square Meters Or Square Feet"
                            : "مساحة غرف النوم المزدوجة بحمام في الأمتار المربعة أو الأقدام المربعة"}
                        </p>
                        <p className="value">
                          {listing.areaOfDoubleBedroomsWithBathInSMetersOrSFeet}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfChaletsOrSeparateHouses !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Chalets Or Separate Houses"
                            : "عدد الشاليهات أو المنازل المنفصلة"}
                        </p>
                        <p className="value">
                          {listing.numOfChaletsOrSeparateHouses}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.areaOfChaletsOrSeparateousesInSMetersOrSFeet !==
                    "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Area Of Chalets Or Separate Houses In Square Meters Or Square Feet"
                            : "مساحة الشاليهات أو المنازل المنفصلة بالمتر المربع أو الأقدام المربعة"}
                        </p>
                        <p className="value">
                          {listing.areaOfChaletsOrSeparateousesInSMetersOrSFeet}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfCarParkingLots !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Car Parking Lots"
                            : "عدد مواقف السيارات"}
                        </p>
                        <p className="value">{listing.numOfCarParkingLots}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfLifts !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false ? "Number Of Lifts" : "عدد المصاعد"}
                        </p>
                        <p className="value">{listing.numOfLifts}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfRestaurantsAndCoffeeShops !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Restaurants And Coffee Shops"
                            : "عدد المطاعم والمقاهي"}
                        </p>
                        <p className="value">
                          {listing.numOfRestaurantsAndCoffeeShops}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.secutriyCamerasAndGaurds !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Secutriy Cameras And Gaurds"
                            : "كاميرات مراقبه وحراسه"}
                        </p>
                        <p className="value">
                          {listing.secutriyCamerasAndGaurds}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.availabilityOfWater !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Availability Of Water"
                            : "توافر المياه"}
                        </p>
                        <p className="value">{listing.availabilityOfWater}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.availabiltyOfInternetService !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Availabilty Of Internet Service"
                            : "توافر خدمة الإنترنت"}
                        </p>
                        <p className="value">
                          {listing.availabiltyOfInternetService}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.availabilityOfGardens !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Availability Of Gardens"
                            : "توافر حدائق"}
                        </p>
                        <p className="value">{listing.availabilityOfGardens}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfInsideSwimmingPoolsAndShowers !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Inside Swimming Pools And Showers"
                            : "عدد حمامات السباحة والاستحمام"}
                        </p>
                        <p className="value">
                          {listing.numOfInsideSwimmingPoolsAndShowers}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfOpenAirSwimmingPoolsAndShowers !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Open Air Swimming Pools And Showers"
                            : "عدد حمامات السباحة والاستحمام في الهواء الطلق"}
                        </p>
                        <p className="value">
                          {listing.numOfOpenAirSwimmingPoolsAndShowers}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfElectricGenerators !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Electric Generators"
                            : "عدد المولدات الكهربائية"}
                        </p>
                        <p className="value">
                          {listing.numOfElectricGenerators}
                        </p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfConferenceRooms !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Conference Rooms"
                            : "عدد غرف المؤتمرات"}
                        </p>
                        <p className="value">{listing.numOfConferenceRooms}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfLounges !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Lounges"
                            : "عدد الصالات"}
                        </p>
                        <p className="value">{listing.numOfLounges}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfWeddingHalls !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Wedding Halls"
                            : "عدد قاعات الأفراح"}
                        </p>
                        <p className="value">{listing.numOfWeddingHalls}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfMeetingHalls !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Meeting Halls"
                            : "عدد قاعات الاجتماعات"}
                        </p>
                        <p className="value">{listing.numOfMeetingHalls}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfConferenceHalls !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Conference Halls"
                            : "عدد قاعات المؤتمرات"}
                        </p>
                        <p className="value">{listing.numOfConferenceHalls}</p>
                      </Spec>
                    </div>
                  )}

                  {listing.numOfStaffRooms !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number Of Staff Rooms"
                            : "عدد غرف العاملين"}
                        </p>
                        <p className="value">{listing.numOfStaffRooms}</p>
                      </Spec>
                    </div>
                  )}
                </div>
              </PropertyDetails>
            )}

            <Separator />
            {listing.type.value === "ld2eqvrOSB5gLr1jEu3w" && (
              <PropertyDetails>
                <h4>
                  {arabic === false
                    ? "Vacant Land Details"
                    : "تفاصيل الأرض الشاغرة"}
                </h4>
                <div className="row">
                  {listing.landclassification !== "" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Land Classifications"
                            : "تصنيف الأرض"}
                        </p>
                        <p className="value">{listing.landclassification}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.isitneara !== "" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "High voltage electricity post/cable"
                            : "عمود الكهرباء ذو الجهد العالي/كابل"}
                        </p>
                        <p className="value">{listing.isitneara}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.natureandlocation !== "" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Nature and Location"
                            : "الطبيعة والموقع"}
                        </p>
                        <p className="value">{listing.natureandlocation}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.doyouhaveaccessto !== "" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Do you have access to"
                            : "هل لديك حق الوصول إلى"}
                        </p>
                        <p className="value">{listing.doyouhaveaccessto}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.variouslegalpossibilities !== "" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Various legal possibilities"
                            : "مختلف الإمكانيات القانونية"}
                        </p>
                        <p className="value">
                          {listing.variouslegalpossibilities}
                        </p>
                      </Spec>
                    </div>
                  )}
                </div>
              </PropertyDetails>
            )}
            {listing.type.value !== "ld2eqvrOSB5gLr1jEu3w" && (
              <PropertyDetails>
                <h4>
                  {arabic === false ? "Property Details" : "تفاصيل العقار"}
                </h4>
                <div className="row">
                  {listing.num_bathrooms !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number of Bathrooms"
                            : "عدد الحمامات"}
                        </p>
                        <p className="value">{listing.num_bathrooms}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.num_bedrooms !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number of Bedrooms"
                            : "عدد غرف النوم"}
                        </p>
                        <p className="value">{listing.num_bedrooms}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.num_living_room !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number of Living Rooms"
                            : "عدد غرف المعيشة"}
                        </p>
                        <p className="value">{listing.num_living_room}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.num_balcony !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number of Balconies"
                            : "عدد الشرفات"}
                        </p>
                        <p className="value">{listing.num_balcony}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.num_dining_rooms !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number of Dining rooms"
                            : "عدد غرف الطعام"}
                        </p>
                        <p className="value">{listing.num_dining_rooms}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.num_salons !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Number of Salons"
                            : "عدد الصالونات"}
                        </p>
                        <p className="value">{listing.num_salons}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.apartments_per_floor !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false
                            ? "Apartments per floor"
                            : "عدد الشقق في كل طابق"}
                        </p>
                        <p className="value">{listing.apartments_per_floor}</p>
                      </Spec>
                    </div>
                  )}
                  {listing.area !== "0" && (
                    <div className="col-lg-4">
                      <Spec>
                        <p className="label">
                          {arabic === false ? "Area" : "المساحة الّة"}
                        </p>
                        <p className="value">
                          {listing.area}{" "}
                          {arabic === false ? "m" : " مترًا مربعًا"}
                          <sup>2</sup>
                        </p>
                      </Spec>
                    </div>
                  )}
                </div>
              </PropertyDetails>
            )}

            {listing.after_repair_value && (
              <>
                <Separator />
                <PropertyDetails>
                  <h4>
                    {arabic === false
                      ? "Property Financials"
                      : "البيانات المالية الخاصة بالعقار"}
                  </h4>
                  <div className="row">
                    {listing.after_repair_value && (
                      <div className="col-lg-4">
                        <Spec>
                          <p className="label">
                            {arabic === false
                              ? "After repair value"
                              : "بعد قيمة الإصلاح"}
                          </p>
                          <p className="value">{listing.after_repair_value}</p>
                        </Spec>
                      </div>
                    )}
                    {listing.current_rent && (
                      <div className="col-lg-4">
                        <Spec>
                          <p className="label">
                            {arabic === false
                              ? "Current Rent"
                              : "الإيجار الحالي"}
                          </p>
                          <p className="value">{listing.current_rent}</p>
                        </Spec>
                      </div>
                    )}
                    {listing.built_tax && (
                      <div className="col-lg-4">
                        <Spec>
                          <p className="label">
                            {arabic === false ? "Built Tax" : "الضرائب المبنية"}
                          </p>
                          <p className="value">{listing.built_tax}</p>
                        </Spec>
                      </div>
                    )}
                    {Object.keys(listing.insurance).length !== 0 && (
                      <div className="col-lg-4">
                        <Spec>
                          <p className="label">Insurance</p>
                          <p className="value">{listing.insurance.label}</p>
                        </Spec>
                      </div>
                    )}
                    {listing.gross_cashflow && (
                      <div className="col-lg-4">
                        <Spec>
                          <p className="label">
                            {arabic === false
                              ? "Gross Cashflow"
                              : "إجمالي التدفق النقدي"}
                          </p>
                          <p className="value">{listing.gross_cashflow}</p>
                        </Spec>
                      </div>
                    )}
                    {listing.cap_rate && (
                      <div className="col-lg-4">
                        <Spec>
                          <p className="label">
                            {arabic === false ? "Cap Rate" : "معدل الحد الأقصى"}
                          </p>
                          <p className="value">{listing.cap_rate}</p>
                        </Spec>
                      </div>
                    )}
                  </div>
                </PropertyDetails>
              </>
            )}
            {listing.addedAmenities.length > 0 && (
              <>
                <Separator />
                <PropertyDetails>
                  <h4>{arabic === false ? "Amenities" : "وسائل الراحة"}</h4>
                  <div className="row">
                    {listing.checkedAmenities &&
                      listing.checkedAmenities.map(amenity => {
                        return (
                          <div className="col-lg-4" key={amenity.value}>
                            <UnitSpec>
                              <img src={UnitCheck} alt="unit check" />
                              <span>{amenity.title}</span>
                            </UnitSpec>
                          </div>
                        )
                      })}
                    {listing.addedAmenities.length > 0 &&
                      listing.addedAmenities.map(amenity => {
                        return (
                          <div className="col-lg-4" key={amenity.value}>
                            <UnitSpec>
                              <img src={UnitCheck} alt="unit check" />
                              <span>{amenity.title}</span>
                            </UnitSpec>
                          </div>
                        )
                      })}
                  </div>
                </PropertyDetails>
              </>
            )}
          </div>
          <div className="col-lg-4">
            <Separator />
            <Share>
              <div className="text">
                {arabic === false ? "Share on: " : "مشاركه فى"}
              </div>
              <div className="socials">
                <FacebookShareButton
                  url={currentUrl}
                  title="Facebook"
                  hashtag="#property"
                  image={listing.featured_image.url}
                  quote={listing.description}
                >
                  <img src={Faceboook} alt="facebook icon" />
                </FacebookShareButton>
                <TwitterShareButton
                  url={currentUrl}
                  title="Twitter"
                  image={listing.featured_image.url}
                  description={listing.description}
                >
                  <img src={Twitter} alt="twitter icon" />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={currentUrl}
                  title="Linkedin"
                  image={listing.featured_image.url}
                  description={listing.description}
                >
                  <img src={Linkedin} alt="linkedin icon" />
                </LinkedinShareButton>
                <WhatsappShareButton
                  url={currentUrl}
                  title="Whatsapp"
                  image={listing.featured_image.url}
                  description={listing.description}
                >
                  <img src={Whatsapp} alt="whatsapp icon" />
                </WhatsappShareButton>
                <TumblrShareButton
                  url={currentUrl}
                  title="Tumblr"
                  image={listing.featured_image.url}
                  description={listing.description}
                >
                  <img src={Tumblr} alt="Tumblr icon" />
                </TumblrShareButton>
                {/* <FacebookMessengerShareButton
                  url={currentUrl}
                  title="Tiktok"
                  image={listing.featured_image.url}
                  description={listing.description}
                >
                <img src={Tiktok} alt="tiktok icon" />
                </FacebookMessengerShareButton> */}
              </div>
            </Share>
            <Separator />
            <FormContent>
              <div className="form">
                <form
                  ref={form}
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                  }}
                >
                  <div
                    className={
                      arabic === false ? "input" : "input align-items-end"
                    }
                  >
                    <label htmlFor="first_name">
                      {arabic === false ? "FIRST NAME*" : "الاسم الاول*"}
                    </label>
                    <input
                      className={arabic === false ? "" : "text-end"}
                      type="text"
                      name="firstName"
                      id="first_name"
                      required
                      placeholder={
                        arabic === false
                          ? "Enter First Name"
                          : "أدخل الاسم الأول"
                      }
                      onChange={validation.handleChange}
                      value={validation.values.firstName}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.firstName &&
                    validation.errors.firstName ? (
                      <p className="error pt-2">
                        {validation.errors.firstName}{" "}
                      </p>
                    ) : null}
                  </div>
                  <div
                    className={
                      arabic === false ? "input" : "input align-items-end"
                    }
                  >
                    <label htmlFor="last_name">
                      {arabic === false ? "LAST NAME*" : "إسم العائلة*"}
                    </label>
                    <input
                      className={arabic === false ? "" : "text-end"}
                      type="text"
                      name="lastName"
                      id="last_name"
                      required
                      placeholder={
                        arabic === false ? "Enter Last Name" : "إسم العائلة"
                      }
                      onChange={validation.handleChange}
                      value={validation.values.lastName}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.lastName &&
                    validation.errors.lastName ? (
                      <p className="error pt-2">
                        {validation.errors.lastName}{" "}
                      </p>
                    ) : null}
                  </div>

                  <div
                    className={
                      arabic === false ? "input" : "input align-items-end"
                    }
                  >
                    <label htmlFor="email_address">
                      {arabic === false
                        ? "EMAIL ADDRESS*"
                        : " البريد الالكترونى*"}
                    </label>
                    <input
                      className={arabic === false ? "" : "text-end"}
                      type="email"
                      name="emailAddress"
                      id="email_address"
                      required
                      placeholder={
                        arabic === false
                          ? "Enter email address"
                          : "أدخل البريد الالكتروني"
                      }
                      onChange={validation.handleChange}
                      value={validation.values.emailAddress}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.emailAddress &&
                    validation.errors.emailAddress ? (
                      <p className="error pt-2">
                        {validation.errors.emailAddress}{" "}
                      </p>
                    ) : null}
                  </div>
                  <div
                    className={
                      arabic === false ? "input" : "input align-items-end"
                    }
                  >
                    <label htmlFor="number">
                      {arabic === false ? "PHONE NUMBER*" : "رقم الهاتف"}
                    </label>
                    <input
                      className={arabic === false ? "" : "text-end"}
                      type="phone"
                      name="number"
                      id="number"
                      required
                      placeholder={
                        arabic === false ? "phone number" : "رقم الهاتف"
                      }
                      onChange={validation.handleChange}
                      value={validation.values.number}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.number && validation.errors.number ? (
                      <p className="error pt-2">{validation.errors.number} </p>
                    ) : null}
                  </div>
                  <div
                    className={
                      arabic === false ? "input" : "input align-items-end"
                    }
                  >
                    <label htmlFor="dateFrom">
                      {arabic === false ? "DATE FROM*" : "التاريخ من*"}
                    </label>
                    <input
                      className={arabic === false ? "" : "text-end"}
                      type="date"
                      name="dateFrom"
                      id="dateFrom"
                      required
                      placeholder={
                        arabic === false ? "available from" : "التاريخ من"
                      }
                      onChange={validation.handleChange}
                      value={validation.values.dateFrom}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.dateFrom &&
                    validation.errors.dateFrom ? (
                      <p className="error pt-2">
                        {validation.errors.dateFrom}{" "}
                      </p>
                    ) : null}
                  </div>
                  <div
                    className={
                      arabic === false ? "input" : "input align-items-end"
                    }
                  >
                    <label htmlFor="dateTo">
                      {arabic === false ? "DATE TO*" : "التاريخ الى *"}
                    </label>
                    <input
                      className={arabic === false ? "" : "text-end"}
                      type="date"
                      name="dateTo"
                      id="dateTo"
                      required
                      placeholder={
                        arabic === false ? "available To" : "التاريخ الى"
                      }
                      onChange={validation.handleChange}
                      value={validation.values.dateTo}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.dateTo && validation.errors.dateTo ? (
                      <p className="error pt-2">{validation.errors.dateTo} </p>
                    ) : null}
                  </div>
                  <div
                    className={
                      arabic === false ? "input" : "input align-items-end"
                    }
                  >
                    <label htmlFor="timeFrom">
                      {arabic === false ? "TIME FROM*" : "الوقت من*"}
                    </label>
                    <input
                      className={arabic === false ? "" : "text-end"}
                      type="time"
                      name="timeFrom"
                      id="timeFrom"
                      required
                      onChange={validation.handleChange}
                      value={validation.values.timeFrom}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.timeFrom &&
                    validation.errors.timeFrom ? (
                      <p className="error pt-2">
                        {validation.errors.timeFrom}{" "}
                      </p>
                    ) : null}
                  </div>
                  <div
                    className={
                      arabic === false ? "input" : "input align-items-end"
                    }
                  >
                    <label htmlFor="TIME TO">
                      {arabic === false ? "TIME TO*" : "الوقت إلى*"}
                    </label>
                    <input
                      className={arabic === false ? "" : "text-end"}
                      type="time"
                      name="timeTo"
                      id="timeTo"
                      required
                      onChange={validation.handleChange}
                      value={validation.values.timeTo}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.timeTo && validation.errors.timeTo ? (
                      <p className="error pt-2">{validation.errors.timeTo} </p>
                    ) : null}
                  </div>

                  <div className="submit">
                    <input
                      type="submit"
                      value={
                        arabic === false ? "SUBMIT MESSAGE" : "تقديم رسالة"
                      }
                    />
                  </div>
                </form>
                {messageSent && (
                  <p className="message-recieved">
                    {arabic === false
                      ? "Your message has been recieved. "
                      : "وقد وردت الرسالة. سنكون على اتصال بك قريبا"}
                  </p>
                )}
              </div>
            </FormContent>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ListingDetails
