import React, { useState } from "react"

const Viewvideo = ({ video }) => {
  const [videoSrc, setVideoSrc] = useState(video)

  return (
    <div>
      {videoSrc && (
        <iframe
          title="Embedded Video"
          width="100%"
          height="400"
          src={videoSrc.replace("watch?v=", "embed/")}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  )
}

export default Viewvideo
