import styled from "styled-components"

export const Container = styled.div`
  background-color: var(--darkBlue);
  padding: 70px 0 140px;

  @media (max-width: 992px) {
    padding: 30px 0;
  }

  .text-container {
    position: relative;
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 0.9rem;
      text-decoration: none;
      color: white;
      margin-bottom: 30px;

      img {
        width: 15px;
      }
    }

    h1 {
      font-weight: bold;
      font-size: 3rem;
      color: white;
    }

    .location {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 30px;

      span {
        color: white;
      }
    }

    .price {
      h3 {
        font-weight: bold;
        color: white;
      }
      @media (max-width: 992px) {
        margin-bottom: 50px;
      }
    }
  }

  .image-container {
    position: relative;

    .hero-img {
      position: absolute;
      bottom: calc(-140px - 25%);
      left: 40px;
      max-height: 400px;
      object-fit: cover;
      width: 100%;
      border-radius: 15px;

      @media (max-width: 992px) {
        position: static;
      }
    }
  }
`

export const Specs = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  bottom: calc(-140px - 25%);
  flex-wrap: wrap;
  box-shadow: 0 3px 26px rgba(0, 0, 0, 0.09);

  @media (max-width: 992px) {
    position: static;
    margin-bottom: 20px;
  }
`

export const Spec = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  border-right: 1px solid #dddddd;
  width: 25%;
  text-align: center;

  &:last-child {
    border-right: none;
  }

  img {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
  }

  .spec-title {
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--lightGreen);
  }

  .spec-description {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    width: 50%;
    border-bottom: 1px solid #dddddd;
  }
`
